import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { LayoutService } from '../layout.service';
import { PteIntroductionService } from '../../_services';
import { LocalItem } from '../../_models';

@Component({
    selector: 'app-layout-2',
    templateUrl: './layout-2.component.html',
    styles: [':host { display: block; }', ':host /deep/ .layout-loading .sidenav-link { transition: none !important; }']
})
export class Layout2Component implements AfterViewInit, OnDestroy {
    // Prevent "blink" effect
    public initialized = false;

    constructor(
        private layoutService: LayoutService,
        private pteIntroductionService: PteIntroductionService,
    ) {
        this.pteIntroductionService.getAllList()
            .subscribe(itemList => {
                localStorage.setItem(LocalItem.pteIntroductionList, JSON.stringify(itemList));
            });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.initialized = true;

            this.layoutService.init();
            this.layoutService.update();
            this.layoutService.setAutoUpdate(true);
        });
    }

    ngOnDestroy() {
        setTimeout(() => {
            this.layoutService.destroy();
        });
    }

    closeSidenav() {
        setTimeout(() => {
            this.layoutService.setCollapsed(true);
        });
    }
}
