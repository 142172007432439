import { Injectable } from '@angular/core';
import { Practice, PracticeType } from '../_models';
import { UtilHelper } from '../_helpers';
import { AngularFireStorageReference, AngularFireStorage } from '@angular/fire/storage';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UUID } from 'angular2-uuid';

@Injectable()
export class PracticeService {

    servicePath: string = 'practice';

    constructor(
        private storage: AngularFireStorage,
        private http: HttpClient
    ) {
    }

    getListByType(type: PracticeType) {
        return this.http.post(`${environment.custom.apiBaseUrl}/${this.servicePath}/getListByType`, {
            type: type
        });
    }

    add(practice: Practice) {
        practice = {
            ...practice,

            id: UUID.UUID(),
            ...UtilHelper.addTimestamp(),
        }

        return this.http.post(`${environment.custom.apiBaseUrl}/${this.servicePath}/add`, practice).toPromise();
    }

    update(practice: Practice) {
        return this.http.post(`${environment.custom.apiBaseUrl}/${this.servicePath}/update`, {
            ...UtilHelper.updateTimestamp(),
            ...practice
        }).toPromise();
    }

    delete(practice: Practice) {
        return this.http.post(`${environment.custom.apiBaseUrl}/${this.servicePath}/delete`, practice).toPromise()
            .then(result => {
                if (practice.audioFile && practice.audioFile.fileUrl) {
                    const fileRef: AngularFireStorageReference = this.storage.ref(practice.audioFile.filePath);
                    fileRef.delete().subscribe(res => {
                        console.log("Practice file deleted.");
                    });
                }
            });
    }
}
