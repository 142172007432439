import { Injectable } from '@angular/core';
import { Headers, Http, Response } from "@angular/http";
import { TranslateLoader } from '@ngx-translate/core';
import { environment } from "../../environments/environment"
import { Observable } from 'rxjs/Observable';

@Injectable()
export class CustomTranslateLoader implements TranslateLoader {
    contentHeader = new Headers({ "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" });

    constructor(private http: Http) { }
    getTranslation(lang: string): Observable<any> {
        var apiAddress = "http://localhost:4200" + "/assets/i18n/" + lang + ".json";
        return Observable.create(observer => {
            this.http.get(apiAddress, { headers: this.contentHeader })
                .subscribe(
                    res => {
                        observer.next(res);
                        observer.complete();
                    },
                    error => {
                        //  failed to retrieve from api, switch to local
                        this.http.get("/assets/i18n/en.json").subscribe((res: Response) => {
                            observer.next(res);
                            observer.complete();
                        })
                    }
                );
        });
    }
}