import { Injectable } from '@angular/core';
import { Course, PteSummary, Practice } from '../_models';

@Injectable()
export class WebStorageService {
    public constructor() { }

    // course
    private _course: Course;
    public get course(): Course {
        return this._course;
    }
    public set course(value: Course) {
        this._course = value;
    }

    // practice
    private _practice: Practice;
    public get practice(): Practice {
        return this._practice;
    }
    public set practice(value: Practice) {
        this._practice = value;
    }
    private _practiceList: Practice[];
    public get practiceList(): Practice[] {
        return this._practiceList;
    }
    public set practiceList(value: Practice[]) {
        this._practiceList = value;
    }
    private _practiceIndex: number;
    public get practiceIndex(): number {
        return this._practiceIndex;
    }
    public set practiceIndex(value: number) {
        this._practiceIndex = value;
    }
}