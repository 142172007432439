export enum LocalItem {
    user = 'user',
    currentLang = 'currentLang',

    pteIntroductionList = 'pteIntroductionList',
    readAloudOffset = 'readAloudOffset',
    repeatSentenceOffset = 'repeatSentenceOffset',
    writeFromDictationOffset = 'writeFromDictationOffset',
    retellLectureOffset = 'retellLectureOffset',
    answerShortQuestionsOffset = 'answerShortQuestionsOffset',
    describeImageOffset = 'describeImageOffset',
    summarySpokenTextOffset = 'SummarySpokenTextOffset'
}

export enum CourseType {
    pdf = 'pdf',
    video = 'video'
}

export enum PracticeType {
    ReadAloud = 'Read Aloud',
    RepeatSentence = 'Repeat Sentence',
    DescribeImage = 'Describe Image',
    WriteFromDictation = 'Write From Dictation',
    RetellLecture = 'RetellLecture',
    AnswerShortQuestions = 'AnswerShortQuestions',
    SummarySpokenText = 'SummarySpokenText'
}

export enum OnlineTestType {
    listening = 'listening',
    speaking = 'speaking',
    reading = 'reading',
    writing = 'writing'
}