import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// *******************************************************************************
// NgBootstrap

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// *******************************************************************************
// Libs

import { ChartsModule as Ng2ChartsModule } from 'ng2-charts/ng2-charts';
import { ToastrModule } from 'ngx-toastr';

// *******************************************************************************
// ngx-translate

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

// *******************************************************************************
// App

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { LayoutModule } from './layout/layout.module';

// *******************************************************************************
// Pages

import { HomeComponent } from './home';
import { NotFoundComponent } from './not-found';
import { LoginComponent } from './login';

// *******************************************************************************
// Auth
import { AuthGuard } from './_guards';
import { JwtInterceptor, ErrorInterceptor, CustomTranslateLoader } from './_helpers';
import {
    AuthService,
    UserService,
    CourseService,
    WebStorageService,
    PteIntroductionService,
    PteSummaryService,
    PracticeService
} from './_services';

// AngularFire2 Modules
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule, StorageBucket } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule, FunctionsRegionToken } from '@angular/fire/functions';

import { environment } from '../environments/environment';

// *******************************************************************************
//
@NgModule({
    declarations: [
        AppComponent,

        // Pages
        HomeComponent,
        NotFoundComponent,

        // Auth
        LoginComponent
    ],

    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgbModule.forRoot(),
        ToastrModule.forRoot(),

        // Libs
        Ng2ChartsModule,

        // ngx-translate
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                // useClass: CustomTranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),

        // App
        AppRoutingModule,
        LayoutModule,

        // Social Login
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFireFunctionsModule
    ],

    providers: [
        Title,
        AppService,
        AuthGuard,
        UserService,
        CourseService,
        WebStorageService,
        PteIntroductionService,
        PteSummaryService,
        PracticeService,

        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: FunctionsRegionToken, useValue: 'us-central1' },
        { provide: StorageBucket, useValue: environment.custom.storageBucket },
        AuthService
    ],

    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/');
}