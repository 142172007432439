import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { LocalItem, User } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate {
    constructor(
        private router: Router
    ) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const user: User = JSON.parse(localStorage.getItem(LocalItem.user));
        if (user && user.isAdmin) {
            return true;
        } else {
            console.error('You must be admin!');
            this.router.navigate(['/login']);
            return false;
        }
    }
}
