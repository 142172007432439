import { Injectable } from '@angular/core';
import { PteSummary } from '../_models';
import { UtilHelper } from '../_helpers';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class PteSummaryService {

    servicePath: string = 'pte-summary';

    constructor(
        private http: HttpClient
    ) {
    }

    getAllList() {
        return this.http.post(`${environment.custom.apiBaseUrl}/${this.servicePath}/getAllList`, {});
    }

    add(pteSummary: PteSummary) {
        const id = pteSummary.name;
        pteSummary = {
            ...pteSummary,

            id: id,
            ...UtilHelper.addTimestamp(),
        }
        return this.http.post(`${environment.custom.apiBaseUrl}/${this.servicePath}/add`, pteSummary).toPromise();
    }

    update(pteSummary: PteSummary) {
        return this.http.post(`${environment.custom.apiBaseUrl}/${this.servicePath}/update`, {
            ...UtilHelper.updateTimestamp(),
            ...pteSummary
        }).toPromise();
    }


    delete(pteSummary: PteSummary) {
        return this.http.post(`${environment.custom.apiBaseUrl}/${this.servicePath}/delete`, pteSummary).toPromise();
    }
}
