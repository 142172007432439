import { Injectable } from '@angular/core';
import { PteIntroduction } from '../_models';
import { UtilHelper } from '../_helpers';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class PteIntroductionService {

    servicePath: string = 'pte-introduction';

    constructor(
        private http: HttpClient
    ) {
    }

    getAllList() {
        return this.http.post(`${environment.custom.apiBaseUrl}/${this.servicePath}/getAllList`, {});
    }

    update(pteIntroduction: PteIntroduction) {
        return this.http.post(`${environment.custom.apiBaseUrl}/${this.servicePath}/update`, {
            ...UtilHelper.updateTimestamp(),
            ...pteIntroduction
        }).toPromise();
    }
}
