import { Component, Input, HostBinding } from '@angular/core';
import { AppService } from '../../app.service';
import { LayoutService } from '../layout.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../_services/auth.service';
import { User, LocalItem } from '../../_models';

@Component({
    selector: 'app-layout-navbar',
    templateUrl: './layout-navbar.component.html',
    styles: [':host { display: block; }'],
    styleUrls: ['../../../vendor/libs/ngx-contextmenu/ngx-contextmenu.scss'],
})
export class LayoutNavbarComponent {
    isExpanded = false;
    isRTL: boolean;

    currentUser: User;

    @Input() sidenavToggle = true;

    @HostBinding('class.layout-navbar') private hostClassMain = true;

    constructor(
        private appService: AppService,
        private layoutService: LayoutService,
        public translate: TranslateService,
        public auth: AuthService
    ) {
        this.isRTL = appService.isRTL;
        this.currentUser = JSON.parse(localStorage.getItem(LocalItem.user));
    }

    currentBg() {
        return `bg-${this.appService.layoutNavbarBg}`;
    }

    toggleSidenav() {
        this.layoutService.toggleCollapsed();
    }
}
