import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services';
import { TRANSLATE } from '../_helpers';
import { ToastrService } from 'ngx-toastr';
import { ApiResponse, LocalItem } from '../_models';
import { User } from 'firebase';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: [
        './login.component.scss'
    ]
})
export class LoginComponent {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private appService: AppService,
        private auth: AuthService,
        private toastrService: ToastrService
    ) {
        this.appService.pageTitle = TRANSLATE('page-title.login');
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.compose([
                Validators.required,
                Validators.email,
            ])],
            password: ['', Validators.required]
        });

        // reset login status
        this.auth.signOut();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.auth.emailLogin(this.f.email.value, this.f.password.value)
            .subscribe((apiResponse: ApiResponse) => {
                if (apiResponse.code == 200) {
                    const user: User = apiResponse.data;
                    localStorage.setItem(LocalItem.user, JSON.stringify(user));

                    this.toastrService.success('Login success', 'Success!');
                    this.loading = false;
                    return this.router.navigate(['/']);
                } else {
                    this.toastrService.error('Login failed');
                    this.loading = false;
                    return false;
                }
            },
                error => {
                    this.toastrService.error('Login failed', error);
                    this.loading = false;
                    return false;
                });
    }
}