import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User, LocalItem } from '../_models/index';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthService {
    servicePath: string = 'auth';
    user: Observable<User | null>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {

    }

    emailLogin(email: string, password: string) {
        return this.http.post(`${environment.custom.apiBaseUrl}/${this.servicePath}/emailLogin`, {
            email: email,
            password: password
        })
    }

    signOut() {
        localStorage.removeItem(LocalItem.user);
        this.router.navigate(['/']);
    }

    isAdmin() {
        const user: User = JSON.parse(localStorage.getItem(LocalItem.user));
        return user && user.isAdmin;
    }

    isStudent() {
        const user: User = JSON.parse(localStorage.getItem(LocalItem.user));
        return user && user.isStudent;
    }

    isExpired() {
        if (this.isAdmin())
            return false;
        const user: User = JSON.parse(localStorage.getItem(LocalItem.user));
        const expiredDateString = user && user.expiredDate;
        if (expiredDateString) {
            const expiredDate = new Date(expiredDateString);
            const now = new Date();
            return expiredDate < now;
        } else {
            return false;
        }
    }

    canAccessReading() {
        if (this.isAdmin())
            return true;
        const user: User = JSON.parse(localStorage.getItem(LocalItem.user));
        return user && user.reading && !this.isExpired();
    }

    canAccessWriting() {
        if (this.isAdmin())
            return true;
        const user: User = JSON.parse(localStorage.getItem(LocalItem.user));
        return user && user.writing && !this.isExpired();
    }

    canAccessListening() {
        if (this.isAdmin())
            return true;
        const user: User = JSON.parse(localStorage.getItem(LocalItem.user));
        return user && user.listening && !this.isExpired();
    }

    canAccessSpeaking() {
        if (this.isAdmin())
            return true;
        const user: User = JSON.parse(localStorage.getItem(LocalItem.user));
        return user && user.speaking && !this.isExpired();
    }
}
