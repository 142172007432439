import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class MyCourseGuard implements CanActivate {
    constructor(
        private auth: AuthService,
        private router: Router
    ) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const baseCategory = route.data["baseCategory"];

        if (baseCategory == 'listening') {
            return this.auth.canAccessListening();
        }
        if (baseCategory == 'speaking') {
            return this.auth.canAccessSpeaking();
        }
        if (baseCategory == 'reading') {
            return this.auth.canAccessReading();
        }
        if (baseCategory == 'writing') {
            return this.auth.canAccessWriting();
        }

        console.error('You must have access to ' + baseCategory);
        this.router.navigate(['/login']);
        return false;
    }
}
