import { Injectable } from '@angular/core';
import { User } from '../_models';
import { UtilHelper } from '../_helpers';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserService {

    servicePath: string = 'user';

    constructor(
        private http: HttpClient
    ) {
    }

    getStudentList() {
        return this.http.post(`${environment.custom.apiBaseUrl}/${this.servicePath}/getStudentList`, {});
    }

    add(user: User, password: string) {
        const id = UtilHelper.getEmailId(user.email);
        user = {
            ...user,
            token: password,

            id: id,
            ...UtilHelper.addTimestamp(),
        }
        return this.http.post(`${environment.custom.apiBaseUrl}/${this.servicePath}/add`, user).toPromise();
    }

    update(user: User) {
        return this.http.post(`${environment.custom.apiBaseUrl}/${this.servicePath}/update`, {
            ...UtilHelper.updateTimestamp(),
            ...user
        }).toPromise();
    }

    delete(user: User) {
        return this.http.post(`${environment.custom.apiBaseUrl}/${this.servicePath}/delete`, user).toPromise();
    }
}
