import { LocalItem } from "../_models";

export class UtilHelper {

    constructor() { }

    /**
     * example: hello world! => Hello World!
     * @param text 
     */
    public static capitalize(text: string) {
        return text.toLowerCase()
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    /**
     * get timestamp obj when create doc in firestore
     */
    public static addTimestamp() {
        const now = new Date();
        return {
            createTime: now.toISOString(),
            createBy: JSON.parse(localStorage.getItem(LocalItem.user)).email || '',
            updateTime: now.toISOString(),
            updateBy: JSON.parse(localStorage.getItem(LocalItem.user)).email || '',
        };
    }

    /**
     * update timestamp obj when update doc in firestore
     */
    public static updateTimestamp() {
        const now = new Date();
        return {
            updateTime: now.toISOString(),
            updateBy: JSON.parse(localStorage.getItem(LocalItem.user)).email || '',
        };
    }

    /**
     * users table uid
     * @param email 
     */
    public static getEmailId(email: string) {
        return email.toLowerCase();
    }

    /**
     * sleep 
     * @param ms 
     */
    public static delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}