import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class NotExpiredGuard implements CanActivate {
    constructor(
        private auth: AuthService,
        private router: Router
    ) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.auth.isExpired()) {
            console.error('You must be not expired!');
            this.router.navigate(['/login']);
            return false;
        } else {
            return true;
        }
    }
}
