// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    theme: {
        navbarBg: 'lighter',
        sidenavBg: 'lighter',
        footerBg: 'lighter'
    },
    firebase: {
        apiKey: "AIzaSyAzDoVxSnxwn6cyYR50BWkMFGIxJQtAf8E",
        authDomain: "nzalc-course-dev.firebaseapp.com",
        databaseURL: "https://nzalc-course-dev.firebaseio.com",
        projectId: "nzalc-course-dev",
        storageBucket: "course-dev-storage.nzacademy.co.nz",
        messagingSenderId: "1085840202665"
    },
    custom: {
        defaultLicense: 3, // month valid video license
        onlineTestTime: {
            listening: 60,
            speaking: 30,
            reading: 40,
            writing: 60
        },
        apiBaseUrl: 'https://course-dev-api.nzacademy.co.nz/api',
        // apiBaseUrl: 'http://localhost:3333/api',
        storageBaseUrl:  'https://course-dev-storage.nzacademy.co.nz',
        storageBucket: 'course-dev-storage.nzacademy.co.nz',
    }
};
