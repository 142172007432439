import { Injectable } from '@angular/core';
import { Course } from '../_models';
import { UtilHelper } from '../_helpers';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class CourseService {

    servicePath: string = 'course';

    constructor(
        private http: HttpClient
    ) {
    }

    getListByType(type: string) {
        return this.http.post(`${environment.custom.apiBaseUrl}/${this.servicePath}/getListByType`, {
            type: type
        });
    }

    getListByCategoryAndType(category: string, type: string) {
        return this.http.post(`${environment.custom.apiBaseUrl}/${this.servicePath}/getListByCategoryAndType`, {
            category: category,
            type: type
        });
    }

    add(course: Course) {
        let id = `${course.category.replace(/\//g, '_')}_${course.type}_${course.name}`;
        id = id.trim();
        let displayBaseCategory = course.category.split('/')[0];
        let displaySubCategory = course.category.split('/')[1].replace(/-/g, ' ');

        course = {
            id: id,
            displayBaseCategory: UtilHelper.capitalize(displayBaseCategory),
            displaySubCategory: UtilHelper.capitalize(displaySubCategory),

            ...course,
            ...UtilHelper.addTimestamp()
        };
        return this.http.post(`${environment.custom.apiBaseUrl}/${this.servicePath}/add`, course).toPromise();
    }

    update(course: Course) {
        return this.http.post(`${environment.custom.apiBaseUrl}/${this.servicePath}/update`, {
            ...UtilHelper.updateTimestamp(),
            ...course
        }).toPromise();
    }

    delete(course: Course) {
        return this.http.post(`${environment.custom.apiBaseUrl}/${this.servicePath}/delete`, course).toPromise();
    }
}
