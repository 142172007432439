import { Component} from '@angular/core';
import { AppService } from '../app.service';
import { TRANSLATE } from '../_helpers';
import { AuthService } from '../_services/auth.service';
import { User, LocalItem } from '../_models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent{
  currentUser: User;

  constructor(
    private appService: AppService,
    public auth: AuthService
  ) {
    this.appService.pageTitle = TRANSLATE('page-title.home');
    this.currentUser = JSON.parse(localStorage.getItem(LocalItem.user));
  }
}
