import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { AppService } from '../app.service';
import { TRANSLATE } from '../_helpers';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: [
        './not-found.component.scss'
    ]
})
export class NotFoundComponent {

    constructor(
        private appService: AppService,
        private locationService: Location
    ) {
        this.appService.pageTitle = TRANSLATE('page-title.not-found');
    }

    onGoBack() {
        this.locationService.back();
    }
}