import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// *******************************************************************************
// Guard

import {
    AuthGuard,
    AdminGuard,
    MyCourseGuard,
    NotExpiredGuard
} from './_guards';

// *******************************************************************************
// Layouts

import { Layout1Component } from './layout/layout-1/layout-1.component';
import { Layout2Component } from './layout/layout-2/layout-2.component';

// *******************************************************************************
// Pages

import { HomeComponent } from './home';
import { NotFoundComponent } from './not-found';
import { LoginComponent } from './login';

// *******************************************************************************
// Routes

const routes: Routes = [

    {
        path: '',
        component: Layout2Component,
        pathMatch: 'full',
        children: [{ path: '', component: HomeComponent }],
        // login
        canActivate: [AuthGuard, NotExpiredGuard]
    },

    {
        path: 'account',
        component: Layout2Component,
        loadChildren: './+account/account.module#AccountModule',
        // access must be admin
        canActivate: [AdminGuard]
    },

    {
        path: 'course',
        component: Layout2Component,
        loadChildren: './+course/course.module#CourseModule',
        // access must be admin
        canActivate: [AdminGuard]
    },

    {
        path: 'listening',
        component: Layout2Component,
        loadChildren: './+listening/listening.module#ListeningModule',
        canActivate: [MyCourseGuard],
        data: { baseCategory: 'listening' }
    },

    {
        path: 'speaking',
        component: Layout2Component,
        loadChildren: './+speaking/speaking.module#SpeakingModule',
        canActivate: [MyCourseGuard],
        data: { baseCategory: 'speaking' }
    },

    {
        path: 'reading',
        component: Layout2Component,
        loadChildren: './+reading/reading.module#ReadingModule',
        canActivate: [MyCourseGuard],
        data: { baseCategory: 'reading' }
    },

    {
        path: 'writing',
        component: Layout2Component,
        loadChildren: './+writing/writing.module#WritingModule',
        canActivate: [MyCourseGuard],
        data: { baseCategory: 'writing' }
    },

    {
        path: 'my-course',
        component: Layout2Component,
        loadChildren: './+my-course/my-course.module#MyCourseModule',
        canActivate: [AuthGuard, NotExpiredGuard]
    },

    {
        path: 'practice',
        component: Layout2Component,
        loadChildren: './+practice/practice.module#PracticeModule',
        canActivate: [AuthGuard, NotExpiredGuard]
    },

    {
        path: 'login', component: LoginComponent
    },

    {
        path: '**', component: NotFoundComponent
    },
];

// *******************************************************************************
//

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
